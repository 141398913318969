/**
 * Created by member > npm run sass:member-forms (or sass:foundation-grid)
 * then copy to src/styles/foundation-grid.css
 */
.foundation-data-attribute-namespace {
  font-family: false; }

/**
 * Ensure base-font-size is 14px like html
 */
meta.foundation-version {
  font-family: "/5.5.3/"; }

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0; }

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 40em)/";
  width: 0; }

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.0625em)/";
  width: 40.0625em; }

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width:40.0625em) and (max-width:64em)/";
  width: 40.0625em; }

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.0625em)/";
  width: 64.0625em; }

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width:64.0625em) and (max-width:90em)/";
  width: 64.0625em; }

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.0625em)/";
  width: 90.0625em; }

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/";
  width: 90.0625em; }

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.0625em)/";
  width: 120.0625em; }

meta.foundation-data-attribute-namespace {
  font-family: false; }

html, body {
  height: 100%; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  font-size: 14px; }

body {
  background: #fff;
  color: #222;
  cursor: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative; }

a:hover {
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

img {
  -ms-interpolation-mode: bicubic; }

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.hide {
  display: none; }

.invisible {
  visibility: hidden; }

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px; }

select {
  width: 100%; }

.row {
  margin: 0 auto;
  max-width: 66.25rem;
  width: 100%; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row.collapse > .column,
  .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row .row {
    margin: 0 -1.46875rem;
    max-width: none;
    width: auto; }
    .row .row:before, .row .row:after {
      content: " ";
      display: table; }
    .row .row:after {
      clear: both; }
    .row .row.collapse {
      margin: 0;
      max-width: none;
      width: auto; }
      .row .row.collapse:before, .row .row.collapse:after {
        content: " ";
        display: table; }
      .row .row.collapse:after {
        clear: both; }

.column,
.columns {
  padding-left: 1.46875rem;
  padding-right: 1.46875rem;
  width: 100%;
  float: left; }

.column + .column:last-child,
.columns + .column:last-child, .column +
.columns:last-child,
.columns +
.columns:last-child {
  float: right; }

.column + .column.end,
.columns + .column.end, .column +
.columns.end,
.columns +
.columns.end {
  float: left; }

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .small-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .small-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .small-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .small-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .small-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .small-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .small-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .small-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .small-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .small-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .small-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .small-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .small-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .small-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .small-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .small-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }
  .column,
  .columns {
    position: relative;
    padding-left: 1.46875rem;
    padding-right: 1.46875rem;
    float: left; }
  .small-1 {
    width: 8.33333%; }
  .small-2 {
    width: 16.66667%; }
  .small-3 {
    width: 25%; }
  .small-4 {
    width: 33.33333%; }
  .small-5 {
    width: 41.66667%; }
  .small-6 {
    width: 50%; }
  .small-7 {
    width: 58.33333%; }
  .small-8 {
    width: 66.66667%; }
  .small-9 {
    width: 75%; }
  .small-10 {
    width: 83.33333%; }
  .small-11 {
    width: 91.66667%; }
  .small-12 {
    width: 100%; }
  .small-offset-0 {
    margin-left: 0 !important; }
  .small-offset-1 {
    margin-left: 8.33333% !important; }
  .small-offset-2 {
    margin-left: 16.66667% !important; }
  .small-offset-3 {
    margin-left: 25% !important; }
  .small-offset-4 {
    margin-left: 33.33333% !important; }
  .small-offset-5 {
    margin-left: 41.66667% !important; }
  .small-offset-6 {
    margin-left: 50% !important; }
  .small-offset-7 {
    margin-left: 58.33333% !important; }
  .small-offset-8 {
    margin-left: 66.66667% !important; }
  .small-offset-9 {
    margin-left: 75% !important; }
  .small-offset-10 {
    margin-left: 83.33333% !important; }
  .small-offset-11 {
    margin-left: 91.66667% !important; }
  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }
  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none; }
  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left; }
  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right; }
  .row.small-collapse > .column,
  .row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.small-uncollapse > .column,
  .row.small-uncollapse > .columns {
    padding-left: 1.46875rem;
    padding-right: 1.46875rem;
    float: left; } }

@media only screen and (min-width: 40.0625em) {
  .medium-push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .medium-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .medium-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .medium-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .medium-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .medium-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .medium-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .medium-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .medium-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }
  .column,
  .columns {
    position: relative;
    padding-left: 1.46875rem;
    padding-right: 1.46875rem;
    float: left; }
  .medium-1 {
    width: 8.33333%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-3 {
    width: 25%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-6 {
    width: 50%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-9 {
    width: 75%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-0 {
    margin-left: 0 !important; }
  .medium-offset-1 {
    margin-left: 8.33333% !important; }
  .medium-offset-2 {
    margin-left: 16.66667% !important; }
  .medium-offset-3 {
    margin-left: 25% !important; }
  .medium-offset-4 {
    margin-left: 33.33333% !important; }
  .medium-offset-5 {
    margin-left: 41.66667% !important; }
  .medium-offset-6 {
    margin-left: 50% !important; }
  .medium-offset-7 {
    margin-left: 58.33333% !important; }
  .medium-offset-8 {
    margin-left: 66.66667% !important; }
  .medium-offset-9 {
    margin-left: 75% !important; }
  .medium-offset-10 {
    margin-left: 83.33333% !important; }
  .medium-offset-11 {
    margin-left: 91.66667% !important; }
  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }
  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none; }
  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left; }
  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right; }
  .row.medium-collapse > .column,
  .row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    padding-left: 1.46875rem;
    padding-right: 1.46875rem;
    float: left; }
  .push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }

@media only screen and (min-width: 64.0625em) {
  .large-push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .large-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .large-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .large-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .large-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .large-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .large-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .large-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .large-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }
  .column,
  .columns {
    position: relative;
    padding-left: 1.46875rem;
    padding-right: 1.46875rem;
    float: left; }
  .large-1 {
    width: 8.33333%; }
  .large-2 {
    width: 16.66667%; }
  .large-3 {
    width: 25%; }
  .large-4 {
    width: 33.33333%; }
  .large-5 {
    width: 41.66667%; }
  .large-6 {
    width: 50%; }
  .large-7 {
    width: 58.33333%; }
  .large-8 {
    width: 66.66667%; }
  .large-9 {
    width: 75%; }
  .large-10 {
    width: 83.33333%; }
  .large-11 {
    width: 91.66667%; }
  .large-12 {
    width: 100%; }
  .large-offset-0 {
    margin-left: 0 !important; }
  .large-offset-1 {
    margin-left: 8.33333% !important; }
  .large-offset-2 {
    margin-left: 16.66667% !important; }
  .large-offset-3 {
    margin-left: 25% !important; }
  .large-offset-4 {
    margin-left: 33.33333% !important; }
  .large-offset-5 {
    margin-left: 41.66667% !important; }
  .large-offset-6 {
    margin-left: 50% !important; }
  .large-offset-7 {
    margin-left: 58.33333% !important; }
  .large-offset-8 {
    margin-left: 66.66667% !important; }
  .large-offset-9 {
    margin-left: 75% !important; }
  .large-offset-10 {
    margin-left: 83.33333% !important; }
  .large-offset-11 {
    margin-left: 91.66667% !important; }
  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto; }
  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }
  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none; }
  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left; }
  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right; }
  .row.large-collapse > .column,
  .row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row.large-uncollapse > .column,
  .row.large-uncollapse > .columns {
    padding-left: 1.46875rem;
    padding-right: 1.46875rem;
    float: left; }
  .push-0 {
    position: relative;
    left: 0;
    right: auto; }
  .pull-0 {
    position: relative;
    right: 0;
    left: auto; }
  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }
  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }
  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }
  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }
  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }
  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }
  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }
  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }
  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }
  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }
  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }
  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }
  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }
  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }
  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }
  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }
  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }
  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }
  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }
  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }
  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }
  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }

/**
 * @import layout
 */
.layout-main h2,
.layout-full h2 {
  margin: 2rem 0 1rem; }
  .layout-main h2:first-of-type,
  .layout-full h2:first-of-type {
    margin-top: 0; }

.layout-main .section-block,
.layout-full .section-block {
  margin: 2rem 0; }
  .layout-main .section-block:first-of-type,
  .layout-full .section-block:first-of-type {
    margin-top: 0; }

@media (min-width: 728px) {
  .layout-main {
    padding-left: 4rem; }
  .layout-sidebar .layout-sidebar__item:first-of-type {
    margin-top: -30px; } }

.layout-side {
  border-right: 1px solid #d2dcea; }
  .layout-side section:first-of-type h2 {
    margin-top: 0; }

ul.list-actions {
  margin: 0; }
  ul.list-actions i {
    padding: 0; }
  ul.list-actions li {
    display: block;
    margin: 0 0 1rem; }
    ul.list-actions li a {
      display: block;
      padding: 10px 30px 10px 50px;
      background-position: 5px center;
      background-repeat: no-repeat;
      background-size: 32px 32px;
      font-size: 1.1rem; }
    ul.list-actions li div {
      display: table-cell;
      vertical-align: middle; }
    ul.list-actions li b {
      display: block;
      color: #10141a;
      font-size: 1.2rem;
      line-height: 1.2;
      font-weight: 400; }
    ul.list-actions li .list-description,
    ul.list-actions li .list-icon {
      color: #70747a; }
    ul.list-actions li .list-description {
      font-size: 0.9rem;
      line-height: 0.9; }
    ul.list-actions li .list-icon {
      width: 30px;
      text-align: center; }
  ul.list-actions li:hover a {
    text-decoration: none; }
    ul.list-actions li:hover a .list-description,
    ul.list-actions li:hover a .list-icon {
      color: #10141a; }

a.list-action-performance {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-02-reviews.svg?v=2.0.0"); }

a.list-action-agreement {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-18-update-agreement.svg?v=2.0.0"); }

a.list-action-redundancy {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-10-restructure.svg?v=2.0.0"); }

a.list-action-disciplinary {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-06-disciplinary.svg?v=2.0.0"); }

a.list-action-additional {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-05-additional.svg?v=2.0.0"); }

a.list-action-edit {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-13-edit.svg?v=2.0.0"); }

a.list-action-remove-person {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-15-remove-person.svg?v=2.0.0"); }

a.list-action-activate {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-08-person.svg?v=2.0.0"); }

a.list-action-add-folder,
a.list-action-show-folder {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-11-add-folder.svg?v=2.0.0"); }

a.list-action-hide-folder {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-20-hide-folder.svg?v=2.0.0"); }

a.list-action-leave-log,
a.list-action-leave-apply {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-01-leave-apply.svg?v=2.0.0"); }

a.list-action-leave-view-calendar {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-04-leave.svg?v=2.0.0"); }

a.list-action-add-file,
a.list-action-add-job {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-14-new-template.svg?v=2.0.0"); }

a.list-action-add {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-11-add-folder.svg?v=2.0.0"); }

a.list-action-recruit {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-07-recruit.svg?v=2.0.0"); }

a.list-action-add-department {
  background-image: url("/static-member-forms/img/icons/svg/app-icons-21-add-department.svg?v=2.0.0"); }

a.section-header-action {
  float: right;
  padding-top: 8px;
  padding-right: 1rem;
  font-size: 1.1rem; }

a.section-action {
  font-size: 1rem;
  display: block; }

a.section-action__left {
  font-size: 1rem; }

a.section-action__right {
  font-size: 1rem;
  float: right; }

/*# sourceMappingURL=foundation-grid.css.map */
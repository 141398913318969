/**
 * Created by member > npm run sass:member-forms (or sass:base-font)
 * then copy to src/styles/base-font.css
 * @TODO NAV CLEANUP:  when new navigation and design is live feb 2025 and we're moving
 * forward remove this
 */
.foundation-data-attribute-namespace {
  font-family: false; }

html, body {
  font-weight: 400;
  font-size: 14px;
  color: #10141a; }

h1, h2, h3, h4 {
  font-weight: 400;
  font-size: 14px; }

h1 {
  margin: 0 0 2rem;
  font-size: 2rem; }

h2 {
  margin: 1.5rem 0 1rem;
  font-size: 1.5rem; }

h3 {
  margin: 1.25rem 0 1rem;
  font-size: 1.25rem; }

h4 {
  margin: 1rem 0 0.5rem;
  font-size: 1.125rem; }

p {
  margin: 0.8rem 0; }

a, .text-like-link {
  color: #384fe5; }

.basicContainer a, .basicContainer .text-like-link {
  color: #fff; }

.text-like-link {
  text-decoration: underline; }

.capitalize {
  text-transform: capitalize; }

.intro-text {
  font-size: 1.9375rem;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 0; }
  .intro-text em {
    font-weight: 400;
    color: #384fe5;
    font-style: normal; }

.sub-intro-text {
  font-size: 1.4375rem;
  line-height: 1.3;
  font-weight: 300;
  margin-bottom: 2rem; }
  .sub-intro-text em {
    color: #384fe5; }

.underlined {
  border-bottom: 1px solid #d2dcea;
  padding-bottom: 10px; }

.myhr-color {
  color: #384fe5; }

@media only screen and (min-width: 40.063em) {
  h1 {
    margin: 0 0 2rem;
    font-size: 2.5rem; }
  h2 {
    margin: 3rem 0 1rem;
    font-size: 1.8rem; }
  h3 {
    margin: 2rem 0 1rem;
    font-size: 1.5rem; } }

/*# sourceMappingURL=base-font.css.map */
/** Prevent a blank-blink until normarise.css is loaded complete */
html,
body {
  margin: 0;
  padding: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

/* Margin and padding reset */
div,
dt,
dd,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

/* list style reset */
/* Lists */
ul,
ol,
dl {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.25;
  list-style: none;
  margin: 0;
  padding: 0;
}

/* myhr-logo class to test if included in production SSR - YES*/
.myhr-logo {
  width: 150px;
  display: block;
}

/* ul li styles undo for auto complete */
ul li.MuiAutocomplete-option {
  margin-bottom: 0 !important;
  line-height: normal !important;
}

/* utility classes */
.mt-0 {
  margin-top: 0 !important;
}

abbr {
  cursor: help;
}

a {
  color: #384fe5;
}

a:hover,
a:focus,
a:active {
  color: #0a184b; /* Navy */
}

a.icon {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

a.underline {
  text-decoration: underline;
}

a.subtle {
  /* @TODO Can we use props here? */
  /* color: ${(props) => props.theme.palette.text.primary}; */
  color: #121316;
}

a.subtle:hover,
a.subtle:focus,
a.subtle:active {
  color: #384fe5;
}

.text-secondary {
  /* color: ${(props) => props.theme.palette.text.secondary}; */
  color: #6a6f81;
}

.text-disabled {
  /* color: ${(props) => props.theme.palette.text.disabled}; */
  color: #9da1af;
}

.text-success {
  /* color: ${(props) => props.theme.palette.text.success}; */
  color: #007a54;
}

.text-warning {
  /* color: ${(props) => props.theme.palette.text.warning}; */
  color: #cc0041;
}

.text-pre-line {
  /* Ensures newlines display as a line break */
  white-space: pre-line;
}

div.paragraph-margin {
  /*
   * To use a `span` where we cannot use a `p` due to nesting errors.
   * @see @link https://github.com/MyHR/member-forms/pull/2394
   */
  margin: 0.25rem 0;
}

ul.list,
ul.list-bullet {
  margin: 0 0 16px;
}

ul.list-bullet {
  padding-left: 16px;
  list-style: disc;
}

ul.list li,
ul.list-bullet li {
  margin-bottom: 12px;
}

/* Remove top margin from first child (5 levels deep) */
.margin-reset > :first-child,
.margin-reset > :first-child > :first-child,
.margin-reset > :first-child > :first-child > :first-child,
.margin-reset > :first-child > :first-child > :first-child > :first-child,
.margin-reset
  > :first-child
  > :first-child
  > :first-child
  > :first-child
  > :first-child {
  margin-top: 0;
}

/* Show a banner in a snackbar. */
/* Note: the border radius must match the Banner so the shadow looks correct. */
.snackbar-banner {
  padding: 0 !important;
  background-color: transparent !important;
  border-radius: var(--radius-xl, 12px) !important;

  .SnackbarItem-message {
    padding: 0 !important;
  }
}

@media print {
  [id^='userpilot'] {
    display: none !important;
  }
}

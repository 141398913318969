/* Add margin to the right of icons */
.fa {
  margin-right: 0.5rem;
}

a {
  text-decoration: none;
  transition: all 0.2s ease;
}
a,
.text-like-link {
  color: #384fe5;
}
a {
  color: #384fe5;
  line-height: inherit;
  text-decoration: none;
}

a,
.text-like-link {
  color: #384fe5;
}

/* Base font line-height for p */
p {
  margin: 8px 0 12px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}
